<template>
  <!--
    Lists the startFinishConfigs and enables the user to edit them.
  -->
  <Portlet
    :title="$t('startFinishConfigDisplayComponent.startFinishConfig')"
    icon="cogs"
    class="startFinishConfigDisplay"
  >
    <div
      v-if="configData"
      slot="buttons"
    >
      <div
        style="display: flex"
      >
        <font-awesome-icon
          v-if="showReloadButton"
          v-tooltip="$t('configReload.reloadStartFinishConfig')"
          :class="['alt-pointer color-primary mr-3 mt-3', { 'fa-spin' : reloadLoading }]"
          icon="sync-alt"
          style="vertical-align: middle;"
          @click="reloadConfig()"
        />
        <div>
          <span
            v-tooltip.top="configData.updatedAt != null ? `${ configData.updatedAt | dateTime_dateTime }` : false"
            class="mb-4"
          >{{ $t('startFinishConfigDisplayComponent.updatedBy') }} {{ configData.user }} {{ configData.updatedAt | dateTime_fromNow }}</span>
          <br>
          <span
            v-tooltip.left="configData.createdAt != null ? `${ configData.createdAt | dateTime_dateTime }` : false"
            class="small"
          >{{ $t('startFinishConfigDisplayComponent.created') }} {{ configData.createdAt | dateTime_fromNow }}</span>
        </div>
      </div>
    </div>
    <LoadingPlaceholder v-if="loading" />
    <template v-else>
      <template v-if="configData">
        <Portlet
          title="Runtime"
          icon="stopwatch"
          class="startFinishConfigDisplay"
        >
          <div slot="buttons">
            <button
              class="btn btn-sm btn-primary float-right"
              @click="showRuntimeForm"
            >
              <font-awesome-icon
                class="mr-2"
                icon="edit"
              />
              <span>{{ $t('edit') }}</span>
            </button>
          </div>
          <table class="defaultTable mb-3">
            <tbody>
              <tr>
                <td>{{ $t('startFinishConfigDisplayComponent.preRuntimeMaximum') }}</td>
                <td>{{ configData.preRuntimeMaximum }}s</td>
              </tr>
              <tr>
                <td>{{ $t('startFinishConfigDisplayComponent.runtimeMaximum') }}</td>
                <td>{{ configData.runtimeMaximum }}s</td>
              </tr>
              <tr>
                <td>{{ $t('startFinishConfigDisplayComponent.runtimeMinimum') }}</td>
                <td>{{ configData.runtimeMinimum }}s</td>
              </tr>
              <tr>
                <td>{{ $t('startFinishConfigDisplayComponent.startWithIdentification') }}</td>
                <td>{{ configData.startWithIdentification }}</td>
              </tr>
            </tbody>
          </table>
        </Portlet>

        <Portlet
          :title="$t('startFinishConfigDisplayComponent.openingHours')"
          icon="clock"
          class="startFinishConfigDisplay mt-2"
        >
          <div slot="buttons">
            <button
              class="btn btn-sm btn-primary float-right"
              @click="addOpeningHours()"
            >
              <font-awesome-icon
                class="mr-2"
                icon="plus"
              />
              <span>{{ $t('add') }}</span>
            </button>
          </div>
          <table class="defaultTable mb-3">
            <tbody>
              <tr
                v-for="(openingHours, index) in configData.openingHours"
                :key="`StartFinishConfigDisplay-li-${ index }`"
              >
                <td>{{ openingHours.openTime }} - {{ openingHours.closeTime }}</td>
                <td>
                  <div class="d-none d-sm-block btn-group btn-group-sm">
                    <button
                      class="btn btn-sm btn-primary"
                      @click="showOpeningHoursFormEdit(index)"
                    >
                      <font-awesome-icon
                        class="mr-2"
                        icon="edit"
                      />
                      <span>{{ $t('edit') }}</span>
                    </button>
                    <button
                      class="btn btn-sm btn-secondary"
                      @click="openConfirmDeleteModal(index)"
                    >
                      <font-awesome-icon
                        class="mr-2 gray"
                        icon="trash"
                      />
                      <span>{{ $t('remove') }}</span>
                    </button>
                  </div>
                  <div class="d-sm-none">
                    <button
                      class="btn btn-sm btn-primary btn-block"
                      @click="showOpeningHoursFormEdit(index)"
                    >
                      <font-awesome-icon
                        class="mr-2"
                        icon="edit"
                      />
                      <span>{{ $t('edit') }}</span>
                    </button>
                    <button
                      class="btn btn-sm btn-secondary btn-block"
                      @click="openConfirmDeleteModal(index)"
                    >
                      <font-awesome-icon
                        class="mr-2 gray"
                        icon="trash"
                      />
                      <span>{{ $t('remove') }}</span>
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </Portlet>
      </template>

      <template v-else>
        {{ $t('noDataAvailable') }}
      </template>

      <router-link
        :to="{ path: `/installation/${ installationId }/tracker/config` }"
        class="btn btn-secondary float-left mt-2"
      >
        <font-awesome-icon
          class="mr-1"
          icon="caret-left"
        />
        <span>
          {{ $t('back') }}
        </span>
      </router-link>
      <div class="clearfix" />

      <Sidebar
        :show-sidebar="showSidebarBoolean"
        @close="hideSidebar"
      >
        <component
          :is="currentComponent"
          :installation-id="installationId"
          :lane-number="laneNumber"
          :current-id="currentId"
          @StartFinishConfig-SetConfig="setConfig()"
          @getConfig="getConfig()"
          @setReloadButton="setReloadButton"
          @hide="hideSidebar"
        />
      </Sidebar>
    </template>

    <SweetModal
      ref="confirmDelete"
      :title="$t('startFinishConfigDisplayComponent.deleteOpeningHours')"
      icon="warning"
      blocking
      class="overflowHidden"
    >
      <p>{{ $t('startFinishConfigDisplayComponent.areYouSureYouWantToDelete') }}</p>
      <button
        slot="button"
        class="btn btn-secondary float-left mb-3"
        @click="$refs.confirmDelete.close()"
      >
        <font-awesome-icon
          class="mr-2 gray"
          icon="times"
        />
        <span>{{ $t('cancel') }}</span>
      </button>
      <button
        slot="button"
        class="btn btn-danger float-right mb-3"
        @click="removeOpeningHours()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="trash"
        />
        <span>{{ $t('delete') }}</span>
      </button>
      <div class="clearfix" />
    </SweetModal>
  </Portlet>
</template>

<script>
import Sidebar from '@/components/Base/Sidebar.vue';
import { SweetModal, SweetModalTab } from 'sweet-modal-vue';

import { dateTimeMixin } from '@/mixins/dateTimeMixin.js';
import { errorMixin } from '@/mixins/errorMixin.js';

export default {
  name: 'StartFinishConfigDisplay',
  components: {
    Sidebar,
    StartFinishConfigRuntimeForm: () => import('@/components/Config/StartFinish/StartFinishConfigRuntimeForm.vue'),
    StartFinishConfigOpeningHoursFormAdd: () => import('@/components/Config/StartFinish/StartFinishConfigOpeningHoursFormAdd.vue'),
    StartFinishConfigOpeningHoursFormEdit: () => import('@/components/Config/StartFinish/StartFinishConfigOpeningHoursFormEdit.vue'),
    SweetModal,
    SweetModalTab
  },
  mixins: [
    dateTimeMixin,
    errorMixin
  ],
  props: {
    installationId: {
      type: String,
      required: true
    },
    laneNumber: {
      type: String,
      required: false,
      default () {
        return null;
      }
    }
  },
  data () {
    return{
      loading: true,
      configData: null,
      showSidebarBoolean: false,
      currentComponent: null,
      currentId: 0,
      deleteIndex: null,
      showReloadButton: false,
      reloadLoading: false
    }
  },
  created () {
    this.getConfig();
  },
  methods: {
    addOpeningHours () {
      this.showOpeningHoursFormAdd(this.configData.openingHours.length + 1);
    },
    openConfirmDeleteModal (index) {
      this.deleteIndex = index;
      this.$refs.confirmDelete.open();
    },
    removeOpeningHours () {
      this.configData.openingHours.splice(this.deleteIndex, 1);
      this.deleteIndex = null;
      this.$refs.confirmDelete.close();
      this.setConfig();
    },
    showRuntimeForm () {
      this.currentComponent = 'StartFinishConfigRuntimeForm';
      this.showSidebarBoolean = true;
    },
    showOpeningHoursFormAdd (value) {
      this.currentId = value;
      this.currentComponent = 'StartFinishConfigOpeningHoursFormAdd';
      this.showSidebarBoolean = true;
    },
    showOpeningHoursFormEdit (value) {
      this.currentId = value;
      this.currentComponent = 'StartFinishConfigOpeningHoursFormEdit';
      this.showSidebarBoolean = true;
    },
    showSidebar () {
      this.showSidebarBoolean = true;
    },
    hideSidebar () {
      this.showSidebarBoolean = false;
      this.currentComponent = null;
      this.currentId = null;
    },
    setReloadButton (value) {
      this.showReloadButton = value;
    },
    getConfig () {
      let url = `/Config/GetStartFinishConfig?installationId=${ this.installationId }`; 
      if (this.laneNumber)
      {
        url += `&laneNumber=${ this.laneNumber }`
      }
      this.axios.get(url)
        .then((response) => {
          this.configData = response.data;
          this.error_clear();
          this.hideSidebar();
        })
        .finally(() => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    setConfig () {
      let url = `/Config/UpdateStartFinishConfig?installationId=${ this.installationId }`; 
      if (this.laneNumber)
      {
        url += `&laneNumber=${ this.laneNumber }`
      }
      this.axios.post(url, this.configData)
        .then((response) => {
          if (response && response.status == 204) {
            this.setReloadButton(true);
            this.$snotify.error(this.$t('configReload.failedReload'));
          } else {
            this.setReloadButton(false);
          }

          this.$snotify.success(this.$t('startFinishConfigDisplayComponent.successfullySaved'));
          this.getConfig();
          this.error_clear();
        })
        .catch((error) => {
          this.$snotify.error(this.$t('startFinishConfigDisplayComponent.notSaved'));
          this.error_clear();
          this.error_validate(error);
        });
    },
    reloadConfig () {
      this.reloadLoading = true;
      let url = `/Config/ReloadStartFinishConfig?installationId=${ this.installationId }`; 
      if (this.laneNumber)
      {
        url += `&laneNumber=${ this.laneNumber }`;
      }
      this.axios.post(url)
        .then((response) => {
          if (response && response.status == 204) {
            this.setReloadButton(true);
            this.$snotify.error(this.$t('configReload.failedReload'));
          } else {
            this.setReloadButton(false);
            this.getConfig();
          }
        })
        .finally(() => {
          this.reloadLoading = false;
        })
        .catch((error) => {
          this.error_clear();
          this.error_validate(error);
        });
    }
  }
}
</script>